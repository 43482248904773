// @ts-nocheck
import React, { useState } from "react";
import { Input } from "./Input";
import InputField from "./InputField";

const Spinner = () => (
  <svg
    className="animate-spin h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export default function CarrierForm({ onSubmit, isLoading }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    dot_number: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate DOT number
    if (!formData.dot_number || formData.dot_number.trim() === "") {
      return; // Add error handling if needed
    }

    // Ensure dot_number is passed as a string
    const submissionData = {
      ...formData,
      dot_number: formData.dot_number.trim(),
    };

    await onSubmit(submissionData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value.trim(), // Trim whitespace as user types
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mx-auto max-w-xl space-y-6 relative z-10"
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-8 gap-y-6">
          <InputField
            label="Your First name"
            htmlFor="first-name"
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="John"
            ariaLabel="First name"
            variant="dark"
          />

          <InputField
            label="Your Last name"
            htmlFor="last-name"
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Doe"
            ariaLabel="Last name"
            variant="dark"
          />
        </div>

        <InputField
          label="Your Company Name"
          htmlFor="company"
          name="companyName"
          type="text"
          value={formData.companyName}
          onChange={handleInputChange}
          placeholder="Your company name"
          ariaLabel="Company name"
          variant="dark"
        />

        <InputField
          label="Your Email"
          htmlFor="email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="you@example.com"
          ariaLabel="Email address"
          variant="dark"
        />

        <hr className="border-white/10 mb-4 mt-2 w-full" />

        <InputField
          label="DOT Number to Lookup"
          htmlFor="dot_number"
          name="dot_number"
          type="text"
          value={formData.dot_number}
          onChange={handleInputChange}
          placeholder="Enter DOT number"
          ariaLabel="DOT number"
          variant="dark"
        />
      </div>

      <div className="mt-10">
        <button
          type="submit"
          disabled={isLoading}
          className={`flex justify-center items-center w-full rounded-md bg-gradient-to-r from-[#143560] to-[#119DA5] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#119DA5] disabled:opacity-70 disabled:cursor-not-allowed`}
        >
          {isLoading ? (
            <div className="flex items-center space-x-2">
              <Spinner />
              <span>Processing...</span>
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
