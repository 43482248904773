import React from "react";

const TIME_SLOTS = [
  "9:00 AM - 11:00 AM",
  "11:00 AM - 1:00 PM",
  "1:00 PM - 3:00 PM",
  "3:00 PM - 5:00 PM",
];

const TimeSlotSelector = ({ selected, onSelect }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {TIME_SLOTS.map((time) => (
        <button
          key={time}
          onClick={() => onSelect(time)}
          className={`
            p-4 rounded-md transition-colors
            ${
              selected === time
                ? "bg-primary text-white"
                : "bg-gray-100 hover:bg-gray-200"
            }
            focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2
          `}
        >
          {time}
        </button>
      ))}
    </div>
  );
};

export default TimeSlotSelector;
