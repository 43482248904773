import React, { useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import driverInformation from "../../../public/static/screenshots/DriverVerification.png";
import cargoPhoto from "../../../public/static/screenshots/ApplicationAndMobile.png";
import driverVerification from "../../../public/static/screenshots/DriverVerificationPage.png";
import shipmentInformation from "../../../public/static/screenshots/VerifyCompliance.png";

export default function Example() {
  // Create refs for each grid item
  const topLeftRef = useRef(null);
  const topRightRef = useRef(null);
  const bottomLeftRef = useRef(null);
  const bottomRightRef = useRef(null);

  // Create spring animations with slower movement
  const springConfig = {
    mass: 2, // Increased for more weight
    tension: 180, // Reduced for slower movement
    friction: 60, // Kept same for smoothness
  };

  const [topLeftSpring, topLeftApi] = useSpring(() => ({
    from: { x: -200, opacity: 0 },
    config: springConfig,
  }));

  const [topRightSpring, topRightApi] = useSpring(() => ({
    from: { x: 200, opacity: 0 },
    config: springConfig,
  }));

  const [bottomLeftSpring, bottomLeftApi] = useSpring(() => ({
    from: { x: -200, opacity: 0 },
    config: springConfig,
  }));

  const [bottomRightSpring, bottomRightApi] = useSpring(() => ({
    from: { x: 200, opacity: 0 },
    config: springConfig,
  }));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add sequential delays to each animation
            if (entry.target === topLeftRef.current) {
              topLeftApi.start({ x: 0, opacity: 1, delay: 0 });
            } else if (entry.target === topRightRef.current) {
              topRightApi.start({ x: 0, opacity: 1, delay: 400 });
            } else if (entry.target === bottomLeftRef.current) {
              bottomLeftApi.start({ x: 0, opacity: 1, delay: 800 });
            } else if (entry.target === bottomRightRef.current) {
              bottomRightApi.start({ x: 0, opacity: 1, delay: 1200 });
            }
          }
        });
      },
      {
        threshold: 0.3, // Increased threshold so animation starts a bit later
        rootMargin: "-50px", // Adds a small buffer before triggering
      }
    );

    // Observe all grid items
    [topLeftRef, topRightRef, bottomLeftRef, bottomRightRef].forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="bg-gray-900 py-24 sm:py-32 relative overflow-hidden">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8 relative z-10">
        <h2 className="text-base/7 font-semibold text-[#119DA5]">
          Catch Double Brokering and Identity Theft
        </h2>
        <p className="mt-2 max-w-lg text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          Automatically vet carriers and catch identity theft.
        </p>
        <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
          {/* Top Left */}
          <animated.div
            ref={topLeftRef}
            style={topLeftSpring}
            className="flex p-px lg:col-span-4 max-h-[36rem] min-h-[36rem]"
          >
            <div className="overflow-hidden rounded-lg w-full bg-gray-800 ring-1 ring-white/15 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]">
              <img
                alt=""
                src={driverInformation}
                className="w-full h-[50%] object-cover object-left"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Catch Fraudulent Carriers
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Verify Carrier Authority & Insurance
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Automatically verify DOT authority, insurance coverage, and
                  safety ratings in real-time before every load assignment
                </p>
              </div>
            </div>
          </animated.div>

          {/* Top Right */}
          <animated.div
            ref={topRightRef}
            style={topRightSpring}
            className="flex p-px lg:col-span-2 max-h-[36rem] min-h-[36rem]"
          >
            <div className="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 lg:rounded-tr-[2rem]">
              <img
                alt=""
                src={cargoPhoto}
                className="h-[50%]  w-full object-cover "
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Fraud Signal Detection
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Detect Fraudulent Applications
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Capture signals of fraudulent applications, including
                  suspicious documents, devices, phone numbers, and more.
                </p>
              </div>
            </div>
          </animated.div>

          {/* Bottom Left */}
          <animated.div
            ref={bottomLeftRef}
            style={bottomLeftSpring}
            className="flex p-px lg:col-span-2 max-h-[36rem] min-h-[36rem]"
          >
            <div className="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 lg:rounded-bl-[2rem]">
              <img
                alt=""
                src={driverVerification}
                className="h-80 w-full object-cover object-[25%_2%]"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Verify the Business
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Verify who is handling your cargo
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Use information from trusted sources to verify who is behind
                  the application.
                </p>
              </div>
            </div>
          </animated.div>

          {/* Bottom Right */}
          <animated.div
            ref={bottomRightRef}
            style={bottomRightSpring}
            className="flex p-px lg:col-span-4 max-h-[36rem] min-h-[36rem]"
          >
            <div className="overflow-hidden rounded-lg w-full bg-gray-800 ring-1 ring-white/15 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]">
              <div className="h-80 overflow-hidden">
                <img
                  alt=""
                  src={shipmentInformation}
                  className="w-full h-full object-contain object-[center_1%] transform scale-115 translate-y-20 "
                />
              </div>
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Ensure Compliance
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Verify Compliance & Safety
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Verify compliance and safety metrics with all publicly
                  available relevant regulations and standards.
                </p>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 -z-0 transform-gpu overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 -z-0 transform-gpu overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[-10rem] top-[calc(100%-40rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[-30rem] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
