import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import ycombinator from "../../../public/static/logo/ycombinator.png";
import lynx from "../../../public/static/logo/lynx.png";
import lloydslab from "../../../public/static/logo/lloydslab.png";

const logos = [
  { id: 1, src: ycombinator, alt: "Y Combinator" },
  { id: 2, src: lynx, alt: "Lynx" },
  { id: 3, src: lloydslab, alt: "Lloyd's Lab" },
];

export default function SocialProof() {
  const { x } = useSpring({
    from: { x: 0 },
    to: { x: -50 },
    loop: true,
    immediate: false,
    reset: true,
    reverse: false,
    config: {
      duration: 25000,
      friction: 50,
    },
  });

  return (
    <section
      className="w-full overflow-hidden py-12 opacity-50 mb-24"
      aria-label="Our trusted partners"
      role="region"
    >
      <div className="relative">
        <div className="mx-auto max-w-7xl px-6 lg:px-8  mb-12">
          <h1 className="text-white text-center text-4xl font-semibold">
            Trusted by
          </h1>
        </div>
        <animated.div
          className="flex items-center gap-32"
          style={{
            transform: x.to((value) => `translateX(${value}%)`),
            width: "max-content",
          }}
        >
          {[...logos, ...logos, ...logos, ...logos].map((logo, index) => (
            <div key={`${logo.id}-${index}`} className="flex-shrink-0 px-8">
              <img
                src={logo.src}
                alt={logo.alt}
                className="h-12 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-300"
                loading="lazy"
                aria-hidden="true"
              />
            </div>
          ))}
        </animated.div>
      </div>
    </section>
  );
}
