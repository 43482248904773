// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"; // SEO helper
import InputField from "../Components/InputField";
import { useNavigate } from "react-router-dom";
import DisplayCarrierData from "../Components/DisplayCarrierData";
import CarrierForm from "../Components/CarrierForm";
import { Card } from "../Components/Card";
import { motion } from "framer-motion";
import { supabase } from "../../lib/supabase";
import { generateCarrierReport } from "../Components/ReportHTML";
import { sendEmail } from "../../api/NotificationService";
import {
  useCarrierByDOT,
  useCarrierBasics,
  useCarrierCargoCarried,
  useCarrierOperationClassification,
  useCarrierOOS,
  useCarrierDocketNumbers,
  useCarrierAuthority,
} from "../../hooks/useFMSCA";

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const PublicVetting = () => {
  // Page state for displaying messages and data
  const [carrierData, setCarrierData] = useState(null);
  const [pageError, setPageError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // These states drive our "fetch" side effect.
  // dotNumber holds the submitted DOT number.
  // fetchTrigger allows re-fetching even if the same DOT is submitted.
  // submittedFormData holds form fields needed later (e.g. for sending email)
  const [dotNumber, setDotNumber] = useState(null);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [submittedFormData, setSubmittedFormData] = useState(null);

  // Each hook is initialized with enabled: false (lazy fetching)
  const { refetch: refetchCarrierByDOT, isLoading: isLoadingCarrierByDOT } =
    useCarrierByDOT(dotNumber, { enabled: false });
  const { refetch: refetchCarrierBasics, isLoading: isLoadingCarrierBasics } =
    useCarrierBasics(dotNumber, { enabled: false });
  const { refetch: refetchCarrierCargo, isLoading: isLoadingCarrierCargo } =
    useCarrierCargoCarried(dotNumber, { enabled: false });
  const {
    refetch: refetchCarrierOperation,
    isLoading: isLoadingCarrierOperation,
  } = useCarrierOperationClassification(dotNumber, { enabled: false });
  const { refetch: refetchCarrierOOS, isLoading: isLoadingCarrierOOS } =
    useCarrierOOS(dotNumber, { enabled: false });
  const {
    refetch: refetchCarrierDocketNumbers,
    isLoading: isLoadingCarrierDocketNumbers,
  } = useCarrierDocketNumbers(dotNumber, { enabled: false });
  const {
    refetch: refetchCarrierAuthority,
    isLoading: isLoadingCarrierAuthority,
  } = useCarrierAuthority(dotNumber, { enabled: false });

  // Combined loading state (if needed for UI)
  const isAnyLoading =
    isLoadingCarrierByDOT ||
    isLoadingCarrierBasics ||
    isLoadingCarrierCargo ||
    isLoadingCarrierOperation ||
    isLoadingCarrierOOS ||
    isLoadingCarrierDocketNumbers ||
    isLoadingCarrierAuthority;

  // Form submission: validate input, set up states to trigger the fetch effect.
  const handleFormSubmit = (formData) => {
    setPageError("");
    setMessage("");
    setCarrierData(null);

    // Validate DOT number isn't blank and has only digits.
    if (!formData.dot_number?.trim()) {
      setPageError("Please enter a valid DOT number.");
      return;
    }
    const dotNumberValue = formData.dot_number.trim();
    if (!/^\d+$/.test(dotNumberValue)) {
      setPageError("DOT number must contain only numbers.");
      return;
    }
    // Optionally validate email format with a simple regex.
    if (
      !formData.email?.trim() ||
      !/^\S+@\S+\.\S+$/.test(formData.email.trim())
    ) {
      setPageError("Please enter a valid email address.");
      return;
    }
    if (!formData.companyName?.trim()) {
      setPageError("Please enter your company name.");
      return;
    }
    if (!formData.firstName?.trim()) {
      setPageError("Please enter your first name.");
      return;
    }

    // Save the submitted form data for later use
    setSubmittedFormData(formData);
    setIsSubmitting(true);
    // Update the DOT number (hooks will use this on re-render)
    setDotNumber(dotNumberValue);
    // Increment fetchTrigger so even if the same DOT is used again, our effect will run.
    setFetchTrigger((prev) => prev + 1);
  };

  // Side-effect to fetch carrier data & send email once dotNumber is updated.
  useEffect(() => {
    // Only proceed if we have both the dot number and the form data.
    if (!dotNumber || !submittedFormData) return;

    (async () => {
      try {
        console.log("Fetching carrier data for DOT:", dotNumber);

        // Run all refetch functions concurrently.
        const [byDOT, basics, cargo, operation, oos, docketNumbers, authority] =
          await Promise.all([
            refetchCarrierByDOT(),
            refetchCarrierBasics(),
            refetchCarrierCargo(),
            refetchCarrierOperation(),
            refetchCarrierOOS(),
            refetchCarrierDocketNumbers(),
            refetchCarrierAuthority(),
          ]);

        if (!byDOT?.data) {
          console.error("No carrier data found for DOT:", dotNumber);
          throw new Error(
            "No carrier data found. Please check your DOT number."
          );
        }

        // Assemble the complete carrier data.
        const fullCarrierData = {
          carrierByDOT: byDOT.data,
          carrierBasics: basics.data,
          carrierCargo: cargo.data,
          carrierOperation: operation.data,
          carrierOOS: oos.data,
          carrierDocketNumbers: docketNumbers.data,
          carrierAuthority: authority.data,
        };

        console.log("Full carrier data:", fullCarrierData);
        setCarrierData(fullCarrierData);

        // Generate the report HTML and send email.
        const html = generateCarrierReport(fullCarrierData);
        await sendEmail(
          submittedFormData.email,
          `FMSCA Carrier Lookup Report for ${dotNumber}`,
          html,
          "carrier_report",
          {
            firstName: submittedFormData.firstName,
            lastName: submittedFormData.lastName,
            companyName: submittedFormData.companyName,
          }
        );

        setMessage("Carrier data fetched and email sent successfully!");
      } catch (err) {
        console.error("Detailed error:", err);
        setPageError(
          err.message || "An error occurred. Please try again later."
        );
      } finally {
        setIsSubmitting(false);
      }
    })();
    // Run this effect whenever fetchTrigger changes.
  }, [
    fetchTrigger,
    dotNumber,
    submittedFormData,
    refetchCarrierByDOT,
    refetchCarrierBasics,
    refetchCarrierCargo,
    refetchCarrierOperation,
    refetchCarrierOOS,
    refetchCarrierDocketNumbers,
    refetchCarrierAuthority,
  ]);

  return (
    <div className="bg-gray-900 relative z-0">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>FMSCA Carrier Lookup Tool | Indemni</title>
        <meta
          name="description"
          content="Receive a full lookup report using FMSCA data on a carrier via your email. Verify your DOT number and get detailed carrier data."
        />
        <link rel="canonical" href="https://indemni.com/public-vetting" />
        <meta
          property="og:title"
          content="FMSCA Carrier Lookup Tool | Indemni"
        />
        <meta
          property="og:description"
          content="Enter your DOT number to receive a comprehensive carrier report delivered to your email using FMSCA data."
        />
        <meta property="og:url" content="https://indemni.com/public-vetting" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="relative">
        {/* Top gradient decoration - exact copy from Hero */}
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-10 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>

        {/* Main content */}
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <motion.div
            className="text-center mb-12"
            initial={fadeIn.initial}
            animate={fadeIn.animate}
            transition={fadeIn.transition}
          >
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              FMSCA Carrier Lookup Tool
            </h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Receive a full lookup report using FMSCA data on a carrier via
              your email.
            </p>
          </motion.div>

          <div className="relative bg-transparent rounded-xl">
            <CarrierForm onSubmit={handleFormSubmit} isLoading={isSubmitting} />
            {pageError && (
              <div className="mt-6 p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
                <p className="text-red-400">{pageError}</p>
              </div>
            )}
            {message && (
              <div className="mt-6 p-4 bg-green-500/10 border border-green-500/20 rounded-lg">
                <p className="text-green-400">{message}</p>
              </div>
            )}
          </div>
        </div>

        {/* Bottom gradient decoration - exact copy from Hero */}
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default PublicVetting;
