// useFMSCAQueries.js
import { useQuery } from "@tanstack/react-query";
import FMSCAService from "../api/FMSCA";

/**
 * Best Practice: Keep a consistent pattern for your query keys.
 * Usually, an array of strings (and/or params) is recommended.
 * This helps with caching, invalidation, and concurrency.
 */

// 1. Carrier By Name
export function useCarrierByName(name, options = {}) {
  return useQuery({
    queryKey: ["carrierByName", name],
    // The query function calls your service
    queryFn: () => FMSCAService.getCarrierByName(name),
    // Optional: only fetch when `name` is truthy
    enabled: Boolean(name),
    // You can merge in any other config passed via `options`
    ...options,
  });
}

// 2. Carrier By DOT
export function useCarrierByDOT(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierByDOT", dotNumber],
    queryFn: () => FMSCAService.getCarrierByDOT(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

// 3. Carrier By Docket
export function useCarrierByDocket(docketNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierByDocket", docketNumber],
    queryFn: () => FMSCAService.getCarrierByDocket(docketNumber),
    enabled: Boolean(docketNumber),
    ...options,
  });
}

// 4. Carrier Basics
export function useCarrierBasics(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierBasics", dotNumber],
    queryFn: () => FMSCAService.getCarrierBasics(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

// 5. Carrier Cargo Carried
export function useCarrierCargoCarried(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierCargoCarried", dotNumber],
    queryFn: () => FMSCAService.getCarrierCargoCarried(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

// 6. Carrier Operation Classification
export function useCarrierOperationClassification(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierOperationClassification", dotNumber],
    queryFn: () => FMSCAService.getCarrierOperationClassification(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

// 7. Carrier OOS
export function useCarrierOOS(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierOOS", dotNumber],
    queryFn: () => FMSCAService.getCarrierOOS(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

// 8. Carrier Docket Numbers
export function useCarrierDocketNumbers(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierDocketNumbers", dotNumber],
    queryFn: () => FMSCAService.getCarrierDocketNumbers(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

// 9. Carrier Authority
export function useCarrierAuthority(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierAuthority", dotNumber],
    queryFn: () => FMSCAService.getCarrierAuthority(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}
