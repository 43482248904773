import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase"; // Import supabase client

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  // Simplify menu handlers
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    // Check authentication status when component mounts
    checkAuthStatus();

    // Initialize mobile check
    setIsMobile(window.innerWidth < 768);

    // Handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkAuthStatus = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
    } catch (error) {
      console.error("Error checking auth status:", error);
      setIsLoggedIn(false);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <nav className="shadow-md w-full bg-slate-800 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo and desktop navigation */}
          <div className="flex items-center space-x-8">
            <Link to="/" className="flex-shrink-0">
              <img
                className="h-8 w-auto"
                src="/static/logo.png"
                alt="INDEMNI"
              />
            </Link>
            <div className="hidden md:flex space-x-6">
              <Link
                to="/shippers"
                className="text-white hover:text-gray-300 text-sm font-medium transition-colors duration-200"
              >
                For Shippers
              </Link>
              <Link
                to="/warehouses"
                className="text-white hover:text-gray-300 text-sm font-medium transition-colors duration-200"
              >
                For Warehouses
              </Link>
            </div>
          </div>

          {/* Desktop auth buttons */}
          <div className="hidden md:flex items-center space-x-4">
            {isLoggedIn ? (
              <>
                <Link
                  to="/dashboard"
                  className="text-white hover:text-gray-300 text-sm font-medium transition-colors duration-200"
                >
                  Dashboard
                </Link>
                <button
                  onClick={handleLogout}
                  className="bg-slate-800 text-white hover:bg-slate-700 px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="https://calendly.com/meet-indemni/30min?background_color=000000&text_color=FFFFFF&primary_color=119DA4"
                  className="text-white hover:text-gray-300 text-sm font-medium transition-colors duration-200"
                >
                  Reach out to our sales team
                </Link>
                <Link
                  to="/signin"
                  className="bg-white text-slate-900 hover:bg-gray-100 px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200"
                >
                  Sign In
                </Link>
              </>
            )}
          </div>

          {/* Mobile menu button */}
          {isMobile && (
            <div className="block md:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-slate-800"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`h-6 w-6 ${isMenuOpen ? "hidden" : "block"}`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } md:hidden bg-slate-800 shadow-lg`}
      >
        <div className="px-4 py-3 space-y-2">
          <Link
            to="/shippers"
            className="text-white hover:bg-slate-700 block px-4 py-3 rounded-md text-base font-medium"
            onClick={closeMenu}
          >
            For Shippers
          </Link>
          <Link
            to="/warehouses"
            className="text-white hover:bg-slate-700 block px-4 py-3 rounded-md text-base font-medium"
            onClick={closeMenu}
          >
            For Warehouses
          </Link>

          {/* Auth-dependent links */}
          {isLoggedIn ? (
            <div className="relative z-50">
              <div className="border-t border-slate-700 my-2"></div>
              <Link
                to="/dashboard"
                className="text-white hover:bg-slate-700 block px-4 py-3 rounded-md text-base font-medium cursor-pointer"
                onClick={closeMenu}
              >
                Dashboard
              </Link>
              <a
                href="mailto:support@indemni.com"
                className="text-white hover:bg-slate-700 block px-4 py-3 rounded-md text-base font-medium cursor-pointer"
                onClick={closeMenu}
              >
                Contact for Help
              </a>
              <button
                onClick={() => {
                  handleLogout();
                  closeMenu();
                }}
                className="w-full text-left text-white hover:bg-slate-700 block px-4 py-3 rounded-md text-base font-medium cursor-pointer"
              >
                Logout
              </button>
            </div>
          ) : (
            <>
              <div className="border-t border-slate-700 my-2"></div>
              <Link
                to="/signin"
                className="bg-white text-slate-900 hover:bg-slate-100 block px-4 py-3 rounded-md text-base font-medium"
                onClick={closeMenu}
              >
                Sign In
              </Link>
              <Link
                to="https://calendly.com/meet-indemni/30min?background_color=000000&text_color=FFFFFF&primary_color=119DA4"
                className="text-white hover:bg-slate-700 block px-4 py-3 rounded-md text-base font-medium"
                onClick={closeMenu}
              >
                Reach out to our sales team
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}
