// @ts-nocheck
import React, { useState } from "react";
import InputField from "../../Components/InputField";
import { useNavigate } from "react-router-dom";
import DisplayCarrierData from "../../Components/DisplayCarrierData";
import { useEffect } from "react";

// Import all the hooks you want to call
import {
  useCarrierByDOT,
  useCarrierBasics,
  useCarrierCargoCarried,
  useCarrierOperationClassification,
  useCarrierOOS,
  useCarrierDocketNumbers,
  useCarrierAuthority,
} from "../../../hooks/useFMSCA";

const VetCarrier = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    dot_number: "",
  });
  const [pageError, setPageError] = useState("");
  const [message, setMessage] = useState("");
  const [carrierData, setCarrierData] = useState(null);
  const navigate = useNavigate();

  // Each hook is initialized with enabled: false (lazy fetching)
  const {
    data: carrierByDOTData,
    refetch: refetchCarrierByDOT,
    isLoading: isLoadingCarrierByDOT,
  } = useCarrierByDOT(formData.dot_number, { enabled: false });

  const {
    data: carrierBasicsData,
    refetch: refetchCarrierBasics,
    isLoading: isLoadingCarrierBasics,
  } = useCarrierBasics(formData.dot_number, { enabled: false });

  const {
    data: carrierCargoData,
    refetch: refetchCarrierCargo,
    isLoading: isLoadingCarrierCargo,
  } = useCarrierCargoCarried(formData.dot_number, { enabled: false });

  const {
    data: carrierOperationData,
    refetch: refetchCarrierOperation,
    isLoading: isLoadingCarrierOperation,
  } = useCarrierOperationClassification(formData.dot_number, {
    enabled: false,
  });

  const {
    data: carrierOOSData,
    refetch: refetchCarrierOOS,
    isLoading: isLoadingCarrierOOS,
  } = useCarrierOOS(formData.dot_number, { enabled: false });

  const {
    data: carrierDocketNumbersData,
    refetch: refetchCarrierDocketNumbers,
    isLoading: isLoadingCarrierDocketNumbers,
  } = useCarrierDocketNumbers(formData.dot_number, { enabled: false });

  const {
    data: carrierAuthorityData,
    refetch: refetchCarrierAuthority,
    isLoading: isLoadingCarrierAuthority,
  } = useCarrierAuthority(formData.dot_number, { enabled: false });

  // Helper: check if any query is loading
  const isAnyLoading =
    isLoadingCarrierByDOT ||
    isLoadingCarrierBasics ||
    isLoadingCarrierCargo ||
    isLoadingCarrierOperation ||
    isLoadingCarrierOOS ||
    isLoadingCarrierDocketNumbers ||
    isLoadingCarrierAuthority;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    console.log("carrierData updated:", carrierData);
  }, [carrierData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageError("");
    setMessage("");
    setCarrierData(null);

    // Optionally, you could add validation to ensure dot_number is valid
    if (!formData.dot_number) {
      setPageError("Please enter a valid DOT number.");
      return;
    }

    try {
      // Trigger all queries concurrently
      const [byDOT, basics, cargo, operation, oos, docketNumbers, authority] =
        await Promise.all([
          refetchCarrierByDOT(),
          refetchCarrierBasics(),
          refetchCarrierCargo(),
          refetchCarrierOperation(),
          refetchCarrierOOS(),
          refetchCarrierDocketNumbers(),
          refetchCarrierAuthority(),
        ]);

      // Combine the results into one object for easier consumption
      setCarrierData({
        carrierByDOT: byDOT.data,
        carrierBasics: basics.data,
        carrierCargo: cargo.data,
        carrierOperation: operation.data,
        carrierOOS: oos.data,
        carrierDocketNumbers: docketNumbers.data,
        carrierAuthority: authority.data,
      });
      setMessage("Carrier data fetched successfully!");
    } catch (err) {
      console.error(err);
      setPageError("An error occurred while fetching carrier data.");
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              Vet Carrier
            </h2>
            <p className="text-slate-500 text-sm">
              Please complete the form below to run a vetting process on the
              carrier.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              <InputField
                label="Name"
                placeholder="Carrier Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <InputField
                label="Email"
                placeholder="carrier@example.com"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <InputField
                label="Website"
                placeholder="https://example.com"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
              />
              <InputField
                label="DOT Number"
                placeholder="DOT123456"
                name="dot_number"
                value={formData.dot_number}
                onChange={handleInputChange}
              />
            </div>

            {pageError && <p className="text-red-500 mt-6">{pageError}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}
            {isAnyLoading && <p className="text-red-500 mt-6">Loading...</p>}

            {carrierData && <DisplayCarrierData carrierData={carrierData} />}

            <div className="space-y-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
                disabled={isAnyLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VetCarrier;
