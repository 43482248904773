import React from "react";
import { Helmet } from "react-helmet";
import Hero from "./LandingPageComponents/Hero";
import TheftFeatures from "./LandingPageComponents/TheftFeatures";
import CTASection from "./LandingPageComponents/CTASection";
import FAQ from "./LandingPageComponents/FAQ";
import SocialProof from "./LandingPageComponents/SocialProof";
import VettingFeatures from "./LandingPageComponents/VettingFeatures";
import CargoEvidenceFeatures from "./LandingPageComponents/CargoEvidenceFeatures";

export default function LandingPage() {
  return (
    <>
      <Helmet>
        <title>Indemni - Transforming Cargo Security & Delivery</title>
        <meta
          name="description"
          content="Discover Indemni's innovative platform that streamlines cargo management by preventing theft, verifying shipments, and ensuring secure delivery operations."
        />
        <link rel="canonical" href="https://indemni.com" />
        <meta
          property="og:title"
          content="Indemni - Transforming Cargo Security & Delivery"
        />
        <meta
          property="og:description"
          content="Discover Indemni's innovative platform that streamlines cargo management by preventing theft, verifying shipments, and ensuring secure delivery operations."
        />
        <meta property="og:url" content="https://indemni.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Indemni Landing Page",
              "url": "https://indemni.com",
              "description": "Discover Indemni's innovative platform that streamlines cargo management by preventing theft, verifying shipments, and ensuring secure delivery operations."
            }
          `}
        </script>
      </Helmet>
      <Hero />
      <SocialProof />
      <TheftFeatures />
      <CargoEvidenceFeatures />
      <VettingFeatures />
      <FAQ />
      <CTASection />
    </>
  );
}
