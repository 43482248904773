// @ts-nocheck
import React, { useEffect, useRef } from "react";
import image_v2 from "../../../public/static/screenshots/image_v2.png";
import { useSpring, animated } from "@react-spring/web";
import { motion } from "framer-motion";
import { Button } from "../../Components/Button";
const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const Hero = () => {
  // Add ref to track the element
  const heroRef = useRef(null);

  // Initialize a spring animation with useSpring hook
  const [springs, api] = useSpring(() => ({
    scale: 0.7,
    config: {
      tension: 280,
      friction: 60,
    },
  }));

  useEffect(() => {
    const handleScroll = () => {
      // Skip if ref isn't attached
      if (!heroRef.current) return;

      // Get the element's position and dimensions
      const element = heroRef.current;
      const rect = element.getBoundingClientRect();

      // Get viewport height
      const windowHeight = window.innerHeight;

      // Calculate how far the element is from the viewport top
      const elementTop = rect.top;
      const elementHeight = rect.height;

      // Calculate progress based on element position
      // Progress will be 0 when element enters viewport and 1 when it exits
      const progress = Math.min(
        Math.max(
          1 - (elementTop + elementHeight) / (windowHeight + elementHeight),
          0
        ),
        1
      );

      // Rest of the scale calculation remains the same
      const scale =
        progress < 0.7
          ? 0.6 + (progress / 0.7) * 0.4 // Scale up from 0.6 to 1.0 until 70%
          : progress > 0.95
          ? 1.0 - ((progress - 0.95) / 0.05) * 0.4 // Scale down from 1.0 to 0.6 in last 5%
          : 1.0; // Hold at 1.0 between 70% and 95%

      api.start({ scale });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [api]);

  return (
    <div ref={heroRef} className="bg-gray-900 relative z-0">
      <div className="relative">
        {/* Top gradient decoration */}
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>

        {/* Main content */}
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
            {/* Left column */}
            <div className="lg:col-span-7 flex flex-col justify-center">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                  className="text-center lg:text-left"
                  initial={fadeIn.initial}
                  animate={fadeIn.animate}
                  transition={fadeIn.transition}
                >
                  <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
                    Protect Your Cargo.
                    <br />
                    <span className="text-[#119DA5]">Prevent Theft.</span>
                    <br />
                    Maximize Profits.
                  </h1>
                  <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto lg:mx-0">
                    Supply Chain Risk and Loss Prevention Platform. Protect your
                    valuable cargo while improving your operational efficiency
                  </p>
                  <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                    <Button
                      className="w-full sm:w-auto bg-[#119DA5] text-white px-8 py-4 text-lg rounded-lg hover:bg-[#0E8A91] transition-colors"
                      onClick={() =>
                        (window.location.href =
                          "https://calendly.com/meet-indemni/30min")
                      }
                    >
                      Schedule Demo
                    </Button>
                    <Button
                      className="w-full sm:w-auto bg-[#0E8A91] text-white px-8 py-4 text-lg rounded-lg hover:bg-[#119DA5] transition-colors"
                      onClick={() => (window.location.href = "/vetting")}
                    >
                      Lookup a carrier now
                    </Button>
                  </div>
                </motion.div>
              </div>
            </div>

            {/* Right column */}
            <div className="mt-16 sm:mt-24 lg:col-span-5 lg:mt-0 lg:h-full">
              <div className="relative h-full">
                {/* Security grid pattern background */}
                <div className="absolute -inset-x-8 -inset-y-8 z-0">
                  <div
                    className="absolute inset-0 bg-gradient-to-r from-[#143560]/5 to-[#119DA5]/5"
                    style={{
                      backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1L1 3.5V6c0 2.5 2 4.5 5 5.5 3-1 5-3 5-5.5V3.5L6 1z' stroke='%23119DA5' stroke-opacity='0.1' fill='none'/%3E%3C/svg%3E")`,
                      backgroundSize: "24px 24px",
                    }}
                  />
                </div>

                {/* Floating elements */}
                <div className="absolute -left-4 top-1/4 w-8 h-8 bg-[#119DA5]/10 rounded-full blur-xl animate-pulse" />
                <div className="absolute -right-4 top-3/4 w-12 h-12 bg-[#143560]/10 rounded-full blur-xl animate-pulse delay-1000" />

                {/* Main image container */}
                <div className="relative h-full">
                  {/* Glass card effect */}
                  <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-white/5 rounded-2xl backdrop-blur-xl" />

                  {/* Border glow */}
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-[#119DA5]/30 to-[#143560]/30 rounded-2xl blur-2xl opacity-75" />

                  {/* Main content wrapper */}
                  <div className="relative h-full overflow-hidden rounded-xl border border-white/10 bg-gray-900/90 shadow-[0_0_15px_rgba(17,157,165,0.5)] backdrop-blur-3xl">
                    {/* Animated gradient overlay */}
                    <div className="absolute inset-0 bg-gradient-to-tr from-[#143560]/10 via-transparent to-[#119DA5]/10 animate-gradient-shift" />

                    {/* Scanner line effect */}
                    <div className="absolute inset-0 overflow-hidden">
                      <div className="absolute inset-0 translate-y-full animate-scanner bg-gradient-to-b from-transparent via-[#119DA5]/20 to-transparent" />
                    </div>

                    {/* Image */}
                    <animated.img
                      alt="Shipment dashboard interface showing cargo tracking and security features"
                      src={image_v2}
                      width={2432}
                      height={1542}
                      className="relative z-10 h-full w-[100%] max-w-none object-cover transition-all duration-700 hover:scale-105"
                    />
                  </div>
                </div>

                {/* Bottom decorative elements */}
                <div className="absolute -bottom-4 left-[10%] right-[10%] h-px bg-gradient-to-r from-transparent via-[#119DA5] to-transparent opacity-50 blur-sm" />
                <div className="absolute -bottom-6 left-[20%] right-[20%] h-px bg-gradient-to-r from-transparent via-[#119DA5] to-transparent opacity-30 blur-sm" />
              </div>
            </div>
          </div>
        </div>

        {/* Bottom gradient decoration */}
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
