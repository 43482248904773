// @ts-nocheck
import { supabase } from "../lib/supabase";

// Base URL for generating verification links
const SERVER_URL =
  process.env.REACT_APP_URL_BASE_FOR_SERVER || "http://localhost:3000";

// Sanitize inputs by allowing letters, numbers, specific symbols (including plus), and whitespace
const sanitizeInput = (input) =>
  input.replace(/[^a-zA-Z0-9@._+\-\s]/g, "").trim();

// Extract the access token from the Supabase session; returns an empty string if not available
const getRequestToken = async () => {
  const { data } = await supabase.auth.getSession();
  return data?.session?.access_token || "";
};

interface SendEmailResponse {
  success: boolean;
  messageId?: string;
  error?: string;
}

interface SendMessageResponse {
  success: boolean;
  error?: string;
}

interface Verification {
  shipment_id: string;
  phone_number: string;
}

interface EmailOptions {
  firstName?: string;
  lastName?: string;
  companyName?: string;
}

const NotificationService = {
  /**
   * Send an email using a preset email configuration.
   * The 'from' field is determined by the server based on the email type.
   *
   * @param {string} to - Recipient email address
   * @param {string} subject - Email subject line
   * @param {string} html - Email content in HTML format
   * @param {string} emailType - Type of the email (used to select the config)
   * @param {EmailOptions} [options] - Optional metadata for rate limiting
   * @returns {Promise<Object>} Response object with success status and a messageId on success
   */
  sendEmail: async (to, subject, html, emailType, options = {}) => {
    try {
      const sanitizedTo = sanitizeInput(to);
      const sanitizedSubject = sanitizeInput(subject);
      const requestToken = await getRequestToken();

      // Sanitize optional fields if present
      const sanitizedOptions = {
        firstName: options.firstName
          ? sanitizeInput(options.firstName)
          : undefined,
        lastName: options.lastName
          ? sanitizeInput(options.lastName)
          : undefined,
        companyName: options.companyName
          ? sanitizeInput(options.companyName)
          : undefined,
      };

      // Invoke the "send-email" edge function without TypeScript generics.
      const { data, error } = await supabase.functions.invoke("send-email", {
        body: {
          to: sanitizedTo,
          subject: sanitizedSubject,
          html,
          requestToken,
          emailType,
          ...sanitizedOptions,
        },
      });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  },

  /**
   * Send a generic message (e.g., SMS message) via the 'send-message' function.
   *
   * @param {string} body - Text content of the message
   * @param {string} from - Sender's identifier/number
   * @param {string} to - Recipient's identifier/number
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendMessage: async (body, from, to) => {
    try {
      const sanitizedBody = sanitizeInput(body);
      const sanitizedFrom = sanitizeInput(from);
      const sanitizedTo = sanitizeInput(to);
      const requestToken = await getRequestToken();

      const { data, error } = await supabase.functions.invoke("send-message", {
        body: {
          body: sanitizedBody,
          from: sanitizedFrom,
          to: sanitizedTo,
          requestToken,
        },
      });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  },

  /**
   * Send an SMS message. Uses the same parameter ordering as sendMessage.
   *
   * @param {string} body - SMS text content
   * @param {string} from - Sender's phone number
   * @param {string} to - Recipient's phone number
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendSMS: async (body, from, to) => {
    return NotificationService.sendMessage(body, from, to);
  },

  /**
   * Send a verification SMS containing a link for verification.
   *
   * @param {Object} verification - Object with shipment_id and phone_number properties
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendVerificationSMS: async (verification) => {
    const twilioFrom = process.env.REACT_APP_TWILIO_PHONE_NUMBER;
    if (!SERVER_URL || !twilioFrom) {
      throw new Error("SERVER_URL or TWILIO_PHONE_NUMBER is not set");
    }
    const body = `Please complete your verification at: ${SERVER_URL}/verify/${verification.shipment_id}`;
    return NotificationService.sendSMS(
      body,
      twilioFrom,
      verification.phone_number
    );
  },
};

// Export selected functions for convenience
export const { sendEmail, sendSMS, sendVerificationSMS } = NotificationService;
export type { EmailOptions };
