// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";

const SafetyBasicsTable = ({ basicsContent = [] }) => {
  if (!Array.isArray(basicsContent) || basicsContent.length === 0) {
    return <p>No safety basics available.</p>;
  }

  // Helper function to determine the CSS text color based on the field label and value.
  const getRiskColor = (label, value) => {
    // If value is missing, return neutral color.
    if (value === undefined || value === null || value === "N/A") {
      return "text-gray-400";
    }

    // For numeric values, attempt conversion.
    const numericValue = Number(value);
    switch (label) {
      case "Percentile":
        // Higher percentiles usually indicate poorer safety performance.
        if (numericValue >= 80) return "text-red-500";
        else if (numericValue >= 50) return "text-yellow-500";
        else return "text-green-500";
      case "Measure Value":
        // Example thresholds – adjust based on domain-specific analysis.
        if (numericValue > 100) return "text-red-500";
        else if (numericValue > 50) return "text-yellow-500";
        else return "text-green-500";
      case "Total Inspections":
        // More inspections with violations can be a red flag.
        if (numericValue > 20) return "text-red-500";
        else if (numericValue > 10) return "text-yellow-500";
        else return "text-green-500";
      case "Total Violations":
        // Higher number of violations indicates greater risk.
        if (numericValue > 10) return "text-red-500";
        else if (numericValue > 5) return "text-yellow-500";
        else return "text-green-500";
      case "Threshold Exceeded":
        // If the carrier has exceeded the intervention threshold, show red.
        // (Assumes the value might be a boolean or a string like "Yes"/"No".)
        return value === true || value === "Yes"
          ? "text-red-500"
          : "text-green-500";
      default:
        return "text-gray-400";
    }
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="font-bold text-gray-700">
            Safety Measure
          </TableHead>
          <TableHead className="font-bold text-gray-700">Percentile</TableHead>
          <TableHead className="font-bold text-gray-700">
            Measure Value
          </TableHead>
          <TableHead className="font-bold text-gray-700">
            Total Inspections
          </TableHead>
          <TableHead className="font-bold text-gray-700">
            Total Violations
          </TableHead>
          <TableHead className="font-bold text-gray-700">
            Threshold Exceeded
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {basicsContent.map((item, idx) => {
          const basic = item?.basic || {};
          return (
            <TableRow key={idx}>
              <TableCell className="font-bold text-gray-700">
                {basic?.basicsType?.basicsShortDesc || "N/A"}
              </TableCell>
              <TableCell
                className={getRiskColor("Percentile", basic?.basicsPercentile)}
              >
                {basic?.basicsPercentile || "N/A"}
              </TableCell>
              <TableCell
                className={getRiskColor("Measure Value", basic?.measureValue)}
              >
                {basic?.measureValue || "N/A"}
              </TableCell>
              <TableCell
                className={getRiskColor(
                  "Total Inspections",
                  basic?.totalInspectionWithViolation
                )}
              >
                {basic?.totalInspectionWithViolation || "N/A"}
              </TableCell>
              <TableCell
                className={getRiskColor(
                  "Total Violations",
                  basic?.totalViolation
                )}
              >
                {basic?.totalViolation || "N/A"}
              </TableCell>
              <TableCell
                className={getRiskColor(
                  "Threshold Exceeded",
                  basic?.exceededFMCSAInterventionThreshold
                )}
              >
                {basic?.exceededFMCSAInterventionThreshold || "N/A"}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SafetyBasicsTable;
