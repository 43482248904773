// @ts-nocheck
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FiEdit2,
  FiSave,
  FiX,
  FiDownload,
  FiTag,
  FiAlertCircle,
} from "react-icons/fi";
import { Loader2 } from "lucide-react";
import Toast from "../../Components/Toast";
import { supabase } from "../../../lib/supabase";
import { Card, CardContent } from "../../Components/Card";
import { Button } from "../../Components/Button";
import { Input } from "../../Components/Input";
import Textarea from "../../Components/TextArea";
import { v4 as uuidv4 } from "uuid";
import PhotoGallery from "../../Components/PhotoGallery";
import EditableField from "../../Components/EditableField";
import { sendEmail } from "../../../api/NotificationService";
import Modal from "../../Components/Modal";

const GenerateReportButton = ({ onClick, isGenerating }) => (
  <Button
    variant="default"
    onClick={onClick}
    disabled={isGenerating}
    className="flex items-center space-x-2"
  >
    {isGenerating ? (
      <Loader2 className="w-4 h-4 animate-spin" />
    ) : (
      <FiDownload className="w-4 h-4" />
    )}
    <span>{isGenerating ? "Generating Report..." : "Generate Report"}</span>
  </Button>
);

const ShipmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shipment, setShipment] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [publicReportUrl, setPublicReportUrl] = useState(null);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const sendEmail = async () => {
    setIsSendingEmail(true);
    try {
      const emailBody = `Please view the shipment report at: ${publicReportUrl}`;
      const fromEmail = "support@indemni.com"; // Replace with your sender email
      const toEmail = email; // The recipient's email from your state

      // Use NotificationService to send the email
      await sendEmail(emailBody, toEmail, fromEmail);

      Toast.success("Email sent successfully");
      setShowEmailModal(false);
    } catch (error) {
      console.error("Error sending email:", error);
      Toast.error("Failed to send email");
    } finally {
      setIsSendingEmail(false);
    }
  };

  useEffect(() => {
    // Fetch shipment details
    const fetchShipment = async () => {
      try {
        const { data, error } = await supabase
          .from("shipments")
          .select("*")
          .eq("id", id)
          .single();

        if (error) throw error;
        setShipment(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching shipment:", error);
        Toast.error({
          title: "Error",
          description: "Failed to load shipment details",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchShipment();
  }, [id]);

  const generatePublicReport = async () => {
    setIsGeneratingReport(true);
    try {
      // Check if shipment already has a public_token
      if (shipment.public_token) {
        const publicUrl = `${window.location.origin}/public-report/${shipment.public_token}`;
        window.open(publicUrl, "_blank");
        setPublicReportUrl(publicUrl);
        Toast.success({
          title: "Success",
          description: "Existing public report URL retrieved.",
        });
        return;
      }

      // If no token exists, generate a new one
      const token = uuidv4();

      // Update the shipment with the new token
      const { error } = await supabase
        .from("shipments")
        .update({ public_token: token })
        .eq("id", shipment.id);

      if (error) throw error;

      // Update all photos with the same load_id
      const { error: photosError } = await supabase
        .from("photos")
        .update({ public_token: token })
        .eq("load_id", shipment.load_id);

      if (photosError) throw photosError;

      // Construct the public URL
      const publicUrl = `${window.location.origin}/public-report/${token}`;

      // Open the public report in a new tab
      window.open(publicUrl, "_blank");

      Toast.success({
        title: "Success",
        description: "Public report URL generated successfully.",
      });

      // Store the public URL in state if you need it elsewhere
      setPublicReportUrl(publicUrl);
    } catch (error) {
      console.error("Error generating public report:", error);
      Toast.error({
        title: "Error",
        description: "Failed to generate public report. Please try again.",
      });
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const handlePhotoUpdate = async (side, metadata) => {
    setIsSaving(true);
    try {
      // Fetch the current shipment data
      const { data: currentShipmentData, error: fetchError } = await supabase
        .from("shipments")
        .select("photo")
        .eq("load_id", shipment.load_id)
        .single();

      if (fetchError) throw fetchError;

      // Update the metadata within the JSON object
      const updatedPhotoData = {
        ...currentShipmentData.photo,
        metadata: {
          ...currentShipmentData.photo.metadata,
          [side]: metadata,
        },
      };

      // Update the entire JSON object in the database
      const { error } = await supabase
        .from("shipments")
        .update({ photo: updatedPhotoData })
        .eq("id", shipment.id);

      if (error) throw error;

      // Update local state
      setShipment((prev) => ({
        ...prev,
        photo: {
          ...prev.photo,
          metadata: {
            ...prev.photo.metadata,
            [side]: metadata,
          },
        },
      }));

      Toast.success("Photo details updated successfully");
    } catch (error) {
      console.error("Error updating photo details:", error);
      Toast.error("Failed to update photo details");
    } finally {
      setIsSaving(false);
    }
  };

  // Clean up the field update handler
  const handleFieldUpdate = async (field, value) => {
    setIsSaving(true);
    try {
      const { error } = await supabase
        .from("shipments")
        .update({ [field]: value })
        .eq("id", id);

      if (error) throw error;

      setShipment((prev) => ({ ...prev, [field]: value }));
      Toast.success("Field updated successfully");
    } catch (error) {
      console.error("Error updating field:", error);
      Toast.error("Failed to update field");
    } finally {
      setIsSaving(false);
    }
  };

  // Fetch photos associated with the shipment
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        if (!shipment) return;

        const { data, error } = await supabase
          .from("photos")
          .select("*")
          .eq("load_id", shipment.load_id);

        if (error) throw error;

        // Get signed URLs for the photos
        const photosWithUrls = await Promise.all(
          data.map(async (photo) => {
            const { data: urlData, error: urlError } = await supabase.storage
              .from("loadPhotos")
              .createSignedUrl(photo.photo_url, 3600);

            if (urlError) throw urlError;

            return {
              ...photo,
              url: urlData.signedUrl,
              tags: photo.tags || [],
            };
          })
        );

        setPhotos(photosWithUrls);
      } catch (error) {
        console.error("Error fetching photos:", error);
        Toast.error("Failed to load photos");
      }
    };

    fetchPhotos();
  }, [shipment]);

  const handleUpdatePhotoTags = async (photoId, newTags) => {
    setIsSaving(true);
    try {
      // Update in Supabase
      const { error } = await supabase
        .from("photos")
        .update({ tags: newTags })
        .eq("id", photoId);

      if (error) throw error;

      // Update the local state
      setPhotos((currentPhotos) =>
        currentPhotos.map((photo) =>
          photo.id === photoId ? { ...photo, tags: newTags } : photo
        )
      );

      Toast.success("Tags updated successfully");
    } catch (error) {
      console.error("Error updating tags:", error);
      Toast.error("Failed to update tags");
    } finally {
      setIsSaving(false);
    }
  };

  // Add this useMemo hook to group photos by stage
  const groupedPhotos = useMemo(() => {
    if (photos?.length > 0) {
      return photos.reduce((acc, photo) => {
        const stage = photo.stage_name || "Uncategorized";
        if (!acc[stage]) acc[stage] = [];
        acc[stage].push(photo);
        return acc;
      }, {});
    }
    return {};
  }, [photos]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (!shipment) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-slate-600">Shipment not found</p>
        <Button
          variant="link"
          onClick={() => navigate("/shipments")}
          className="mt-4"
        >
          Back to Shipments
        </Button>
      </div>
    );
  }

  return (
    <div className="bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-8">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-slate-900">
            Shipment Details
          </h1>
          <div className="space-x-4">
            <GenerateReportButton
              onClick={generatePublicReport}
              isGenerating={isGeneratingReport}
            />
          </div>
        </div>

        {/* Photos Section */}
        <Card>
          <CardContent className="p-6">
            <h2 className="text-xl font-semibold text-slate-900 mb-4">
              Photos
            </h2>
            {isLoading ? (
              <div className="text-center py-8 text-slate-500">
                <Loader2 className="w-8 h-8 animate-spin mx-auto mb-2" />
                <p>Loading photos...</p>
              </div>
            ) : photos.length === 0 ? (
              <div className="text-center py-8 text-slate-500">
                No photos available
              </div>
            ) : (
              <div className="space-y-8">
                {Object.entries(groupedPhotos).map(
                  ([stageName, stagePhotos]) => (
                    <div key={stageName} className="space-y-4">
                      <h3 className="text-lg font-medium text-slate-800">
                        {stageName}
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {stagePhotos.map((photo) => (
                          <div
                            key={photo.id}
                            className="border rounded-lg p-4 space-y-3"
                          >
                            {photo.url ? (
                              <img
                                src={photo.url}
                                alt={photo.caption || "Shipment photo"}
                                className="w-full h-48 object-cover rounded-lg cursor-pointer"
                              />
                            ) : (
                              <div className="w-full h-48 bg-gray-200 animate-pulse rounded-lg" />
                            )}

                            <div className="space-y-2">
                              <EditableField
                                label="Tags"
                                type="tags"
                                value={photo.tags || []}
                                onSave={(newTags) =>
                                  handleUpdatePhotoTags(photo.id, newTags)
                                }
                              />

                              {photo.metadata && (
                                <div className="text-xs text-slate-500">
                                  <p>
                                    Taken:{" "}
                                    {new Date(
                                      photo.metadata.timing.takenAt
                                    ).toLocaleString()}
                                  </p>
                                  <p>
                                    Device: {photo.metadata.deviceInfo.model}
                                  </p>
                                  <p>
                                    Quality Score:{" "}
                                    {photo.metadata.quality.blurScore}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </CardContent>
        </Card>

        {/* Shipment Information */}
        <Card>
          <CardContent className="p-6">
            <h2 className="text-xl font-semibold text-slate-900 mb-4">
              Shipment Information
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <EditableField
                label="Load ID"
                value={shipment.load_id}
                onSave={(value) => handleFieldUpdate("load_id", value)}
              />
              <EditableField
                label="Status"
                value={shipment.status}
                onSave={(value) => handleFieldUpdate("status", value)}
              />
              <EditableField
                label="Origin Address"
                value={shipment.origin_address}
                onSave={(value) => handleFieldUpdate("origin_address", value)}
              />
              <EditableField
                label="Destination Address"
                value={shipment.destination_address}
                onSave={(value) =>
                  handleFieldUpdate("destination_address", value)
                }
              />
              <EditableField
                label="Carrier Number"
                value={shipment.carrier_number}
                onSave={(value) => handleFieldUpdate("carrier_number", value)}
              />
              <EditableField
                label="Container Number"
                value={shipment.container_number}
                onSave={(value) => handleFieldUpdate("container_number", value)}
              />
              <EditableField
                label="Pallet Number"
                value={shipment.pallet_number}
                onSave={(value) => handleFieldUpdate("pallet_number", value)}
              />
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h2 className="text-xl font-semibold text-slate-900 mb-4">
              Additional Information
            </h2>
            <div className="space-y-6">
              <EditableField
                label="Notes"
                value={shipment.notes}
                onSave={(value) => handleFieldUpdate("notes", value)}
                type="textarea"
              />
              <EditableField
                label="Tags"
                value={shipment.tags}
                onSave={(value) => handleFieldUpdate("tags", value)}
              />
              <EditableField
                label="Condition"
                value={shipment.condition}
                onSave={(value) => handleFieldUpdate("condition", value)}
              />
              <div className="flex items-center space-x-2">
                <label className="text-sm font-medium text-slate-500">
                  Is Damaged
                </label>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() =>
                    handleFieldUpdate("is_damaged", !shipment.is_damaged)
                  }
                  className={`${
                    shipment.is_damaged
                      ? "text-red-500 hover:text-red-600"
                      : "text-green-500 hover:text-green-600"
                  }`}
                >
                  {shipment.is_damaged ? "Yes" : "No"}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      {publicReportUrl && (
        <>
          <Button onClick={() => setShowEmailModal(true)}>Email Report</Button>
          {showEmailModal && (
            <Modal onClose={() => setShowEmailModal(false)}>
              <h2>Email Report</h2>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Recipient's email"
              />
              <Button onClick={sendEmail} disabled={isSendingEmail}>
                {isSendingEmail ? "Sending..." : "Send Email"}
              </Button>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default ShipmentDetails;
