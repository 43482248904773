// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";

const CarrierBasicInfoTable = ({ carrier }) => {
  if (!carrier) {
    return <p>No carrier information available.</p>;
  }

  // Helper function to determine risk color based on field and value.
  const getRiskColor = (label, value) => {
    // Only apply custom styling for numeric values
    if (typeof value === "number") {
      switch (label) {
        case "Safety Rating":
          return value < 3 ? "text-red-500" : "text-green-500";
        case "Total Drivers":
          return value < 10 ? "text-green-500" : "text-red-500";
        case "Total Power Units":
          return value < 10 ? "text-green-500" : "text-red-500";
        case "Driver Inspections":
          return value < 5 ? "text-green-500" : "text-red-500";
        case "Vehicle Inspections":
          return value < 5 ? "text-green-500" : "text-red-500";
        default:
          return "text-gray-400";
      }
    }
    // For non-numeric values or fields without specific risk logic, use a neutral color.
    return "text-gray-400";
  };

  const rows = [
    { label: "Legal Name", value: carrier.legalName },
    { label: "DOT Number", value: carrier.dotNumber },
    { label: "Allowed to Operate", value: carrier.allowedToOperate },
    {
      label: "Operation",
      value: carrier.carrierOperation?.carrierOperationDesc,
    },
    { label: "Safety Rating", value: carrier.safetyRating },
    { label: "Total Drivers", value: carrier.totalDrivers },
    { label: "Total Power Units", value: carrier.totalPowerUnits },
    { label: "Driver Inspections", value: carrier.driverInsp },
    { label: "Vehicle Inspections", value: carrier.vehicleInsp },
  ];

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Field</TableHead>
          <TableHead>Value</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map((row, idx) => (
          <TableRow key={idx}>
            <TableCell className="font-bold text-gray-700">
              {row.label}
            </TableCell>
            <TableCell className={getRiskColor(row.label, row.value)}>
              {row.value || "N/A"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CarrierBasicInfoTable;
