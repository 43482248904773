// @ts-nocheck
import React, { useState } from "react";
import { useShipmentMutations } from "../../hooks/shipments";
import { Card, CardContent, CardHeader, CardTitle } from "../Components/Card";
import { Button } from "../Components/Button";
import { Input } from "../Components/Input";
import { Progress } from "../Components/Progress";
import { Alert, AlertTitle, AlertDescription } from "../Components/Alert";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import TimeSlotSelector from "../Components/TimeSlotSelector";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { useCargoOwnerById } from "../../hooks/cargoOwner";

const STEPS = [
  { id: 1, title: "Select Date & Time" },
  { id: 2, title: "Location Details" },
  { id: 3, title: "Cargo Information" },
  { id: 4, title: "Additional Details" },
  { id: 5, title: "Review & Confirm" },
];

const ScheduleDelivery = () => {
  const { id: cargoOwnerId } = useParams();
  const { data: cargoOwner } = useCargoOwnerById(cargoOwnerId);

  // Add debug logs
  console.log("Raw cargoOwnerId from URL:", cargoOwnerId);
  console.log("Parsed cargoOwnerId:", parseInt(cargoOwnerId));

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(() => {
    const parsedId = parseInt(cargoOwnerId);
    return {
      cargo_owner_id: parsedId || null,
      status: "Unverified",
      pickup_time: null, // This will store the timestamptz
      origin_address: "",
      destination_address: "",
      company_id: null,
      carrier_number: "",
      container_number: "",
      pallet_number: "",
      tags: "",
      notes: "",
    };
  });
  const navigate = useNavigate();

  const { createShipment } = useShipmentMutations();

  console.log("Form Data:", formData);

  // UI state for date picker and time selector
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const createPickupTimestamp = (date, timeSlot) => {
    if (!date || !timeSlot) return null;

    // Parse time slot (assuming format like "09:00 AM - 10:00 AM")
    const startTime = timeSlot.split(" - ")[0];
    const [hours, minutes] = startTime.split(":");
    const isPM = startTime.includes("PM");

    // Create new date object
    const timestamp = new Date(date);
    let hour = parseInt(hours);

    // Convert to 24-hour format
    if (isPM && hour !== 12) hour += 12;
    if (!isPM && hour === 12) hour = 0;

    timestamp.setHours(hour);
    timestamp.setMinutes(parseInt(minutes));
    timestamp.setSeconds(0);
    timestamp.setMilliseconds(0);

    return timestamp.toISOString();
  };

  const handleInputChange = (field, value) => {
    if (field === "pickupDate") {
      setSelectedDate(value);
      setFormData((prev) => ({
        ...prev,
        pickup_time: createPickupTimestamp(value, selectedTimeSlot),
      }));
    } else if (field === "pickupTimeSlot") {
      setSelectedTimeSlot(value);
      setFormData((prev) => ({
        ...prev,
        pickup_time: createPickupTimestamp(selectedDate, value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [field]: value }));
    }
  };

  const validateForm = () => {
    const errors = [];
    console.log("Type of cargo_owner_id:", typeof formData.cargo_owner_id);
    console.log("Value of cargo_owner_id:", formData.cargo_owner_id);

    if (!formData.cargo_owner_id && formData.cargo_owner_id !== 0) {
      errors.push("Cargo owner is required");
    }
    if (!formData.pickup_time) {
      errors.push("Pickup date and time are required");
    }
    if (!formData.origin_address) errors.push("Origin address is required");
    if (!formData.destination_address)
      errors.push("Destination address is required");

    return errors;
  };

  const sendEmail = async (email, subject, body) => {
    await supabase.functions.invoke("send-email", {
      body: {
        from: "support@indemni.com",
        to: email,
        subject: `Delivery Request from ${cargoOwner.company_name}`,
        html: `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
          <div style="background-color: #f8f9fa; padding: 20px; border-radius: 8px;">
            <h1 style="color: #1a56db; margin-bottom: 20px;">Delivery Request from ${
              cargoOwner.company_name
            }</h1>
            
            <div style="background-color: #ffffff; padding: 20px; border-radius: 4px; border: 1px solid #e5e7eb; margin: 15px 0;">
              <h2 style="color: #374151; margin-bottom: 15px;">Date & Time</h2>
              <p style="color: #374151; font-size: 16px; line-height: 1.5;">
                Pickup Date: ${selectedDate.toLocaleDateString()}<br>
                Time Slot: ${selectedTimeSlot || "Not specified"}
              </p>
            </div>

            <div style="background-color: #ffffff; padding: 20px; border-radius: 4px; border: 1px solid #e5e7eb; margin: 15px 0;">
              <h2 style="color: #374151; margin-bottom: 15px;">Location Details</h2>
              <p style="color: #374151; font-size: 16px; line-height: 1.5;">
                Origin: ${formData.origin_address}<br>
                Destination: ${formData.destination_address}
              </p>
            </div>

            <div style="background-color: #ffffff; padding: 20px; border-radius: 4px; border: 1px solid #e5e7eb; margin: 15px 0;">
              <h2 style="color: #374151; margin-bottom: 15px;">Cargo Information</h2>
              <p style="color: #374151; font-size: 16px; line-height: 1.5;">
                Carrier Number: ${
                  formData.carrier_number || "Not specified"
                }<br>
                Container Number: ${
                  formData.container_number || "Not specified"
                }<br>
                Pallet Number: ${formData.pallet_number || "Not specified"}<br>
                Tags: ${formData.tags || "None"}
              </p>
            </div>

            <div style="background-color: #ffffff; padding: 20px; border-radius: 4px; border: 1px solid #e5e7eb; margin: 15px 0;">
              <h2 style="color: #374151; margin-bottom: 15px;">Additional Details</h2>
              <p style="color: #374151; font-size: 16px; line-height: 1.5;">
                Special Requirements: ${formData.requirements || "None"}<br>
                Status: ${formData.status}
              </p>
            </div>

            <p style="color: #6b7280; font-size: 14px; margin-top: 20px;">
              For any questions or changes, please contact us at support@indemni.com
            </p>
          </div>
        </div>
      `,
      },
    });
  };

  const handleSubmit = async () => {
    console.log(formData);
    try {
      const errors = validateForm();
      if (errors.length > 0) {
        console.log(errors);
        return;
      }

      await createShipment.mutateAsync({
        cargo_owner_id: formData.cargo_owner_id,
        status: formData.status,
        pickup_time: formData.pickup_time,
        origin_address: formData.origin_address,
        destination_address: formData.destination_address,
        company_id: formData.company_id,
        carrier_number: formData.carrier_number,
        container_number: formData.container_number,
        pallet_number: formData.pallet_number,
        tags: formData.tags,
        notes: formData.notes,
      });
      sendEmail(
        formData.notificationEmail,
        "Delivery Schedule Confirmation",
        "Your delivery has been scheduled successfully"
      );
      navigate("/external");
    } catch (error) {
      console.error("Error during shipment creation:", error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Card>
            <CardHeader className="flex flex-col items-center">
              <CardTitle>Select Pickup Date & Time</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex justify-center">
                  <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={(date) => handleInputChange("pickupDate", date)}
                    className="border rounded-md p-3 [&_.rdp-table]:w-full [&_.rdp-caption]:text-lg [&_.rdp-cell]:text-base [&_.rdp-head_th]:text-base"
                    disabled={[{ before: new Date() }, { dayOfWeek: [0, 6] }]}
                  />
                </div>
                <TimeSlotSelector
                  selected={selectedTimeSlot}
                  onSelect={(slot) => handleInputChange("pickupTimeSlot", slot)}
                />
              </div>
            </CardContent>
          </Card>
        );

      case 2:
        return (
          <Card>
            <CardHeader>
              <CardTitle>Location Details</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Input
                  label="Origin Address"
                  className="bg-white"
                  value={formData.origin_address}
                  onChange={(e) =>
                    handleInputChange("origin_address", e.target.value)
                  }
                  placeholder="Enter pickup address"
                  required
                />
                <Input
                  label="Destination Address"
                  className="bg-white"
                  value={formData.destination_address}
                  onChange={(e) =>
                    handleInputChange("destination_address", e.target.value)
                  }
                  placeholder="Enter delivery address"
                  required
                />
              </div>
            </CardContent>
          </Card>
        );

      case 3:
        return (
          <Card>
            <CardHeader>
              <CardTitle>Shipment Details</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Input
                  label="Carrier Number"
                  value={formData.carrier_number}
                  className="bg-white"
                  onChange={(e) =>
                    handleInputChange("carrier_number", e.target.value)
                  }
                  placeholder="Enter carrier number"
                />
                <Input
                  label="Container Number"
                  value={formData.container_number}
                  className="bg-white"
                  onChange={(e) =>
                    handleInputChange("container_number", e.target.value)
                  }
                  placeholder="Enter container number"
                />
                <Input
                  label="Pallet Number"
                  value={formData.pallet_number}
                  className="bg-white"
                  onChange={(e) =>
                    handleInputChange("pallet_number", e.target.value)
                  }
                  placeholder="Enter pallet number"
                />
                <Input
                  label="Tags"
                  value={formData.tags}
                  className="bg-white"
                  onChange={(e) => handleInputChange("tags", e.target.value)}
                  placeholder="Enter tags (comma separated)"
                />
              </div>
            </CardContent>
          </Card>
        );

      case 4:
        return (
          <Card>
            <CardHeader>
              <CardTitle>Additional Details</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Input
                  label="Special Requirements"
                  className="bg-white"
                  value={formData.requirements}
                  onChange={(e) =>
                    handleInputChange("requirements", e.target.value)
                  }
                  placeholder="Any special handling instructions"
                />
                <Input
                  type="email"
                  label="Who to send schedule confirmation to:"
                  className="bg-white"
                  value={formData.notificationEmail}
                  onChange={(e) =>
                    handleInputChange("notificationEmail", e.target.value)
                  }
                  placeholder="Email for shipment schedule confirmation"
                />
              </div>
            </CardContent>
          </Card>
        );

      case 5:
        return (
          <Card>
            <CardHeader>
              <CardTitle>Review & Confirm</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="border rounded-lg p-4">
                  <h1 className="text-xl font-bold mb-12">Delivery Summary</h1>
                  <div className="mt-2 space-y-2">
                    <p className="text-lg">
                      <strong className="text-gray-700">Status:</strong>{" "}
                      {formData.status}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">Pickup Date:</strong>{" "}
                      {formData.pickupDate
                        ? new Date(formData.pickupDate).toLocaleDateString()
                        : "Not specified"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">Pickup Time:</strong>{" "}
                      {formData.pickupTimeSlot || "Not specified"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">Origin Address:</strong>{" "}
                      {formData.origin_address || "Not specified"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">
                        Destination Address:
                      </strong>{" "}
                      {formData.destination_address || "Not specified"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">Carrier Number:</strong>{" "}
                      {formData.carrier_number || "Not specified"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">
                        Container Number:
                      </strong>{" "}
                      {formData.container_number || "Not specified"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">Pallet Number:</strong>{" "}
                      {formData.pallet_number || "Not specified"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">Tags:</strong>{" "}
                      {formData.tags || "None"}
                    </p>
                    <p className="text-lg">
                      <strong className="text-gray-700">Notes:</strong>{" "}
                      {formData.notes || "No additional notes"}
                    </p>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    onClick={handleSubmit}
                    variant="outline"
                    className="transition-all duration-200 active:scale-95 hover:shadow-md active:shadow-sm"
                  >
                    Schedule Delivery
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        );

      default:
        return null;
    }
  };

  return (
    <div className="py-8">
      <div className="mb-8">
        <Progress value={(currentStep / STEPS.length) * 100} />
        <div className="flex justify-between mt-2">
          {STEPS.map((step) => (
            <span
              key={step.id}
              className={`text-sm ${
                currentStep >= step.id ? "text-white" : "text-muted-foreground"
              }`}
            >
              {step.title}
            </span>
          ))}
        </div>
      </div>

      {renderStep()}

      <div className="mt-6 flex justify-between">
        <Button
          className="bg-white text-primary hover:bg-gray-300"
          onClick={() => setCurrentStep((prev) => Math.max(1, prev - 1))}
          disabled={currentStep === 1}
        >
          Previous
        </Button>

        {currentStep < STEPS.length ? (
          <Button
            className="bg-white text-primary hover:bg-gray-300"
            onClick={() =>
              setCurrentStep((prev) => Math.min(STEPS.length, prev + 1))
            }
          >
            Next
          </Button>
        ) : (
          <Button onClick={handleSubmit}>Schedule Delivery</Button>
        )}
      </div>
    </div>
  );
};

export default ScheduleDelivery;
