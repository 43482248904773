// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";

const CargoCarriedTable = ({ cargoContent = [] }) => {
  if (!Array.isArray(cargoContent) || cargoContent.length === 0) {
    return <p>No cargo carried information available.</p>;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="font-bold text-gray-700">
            Cargo Class Description
          </TableHead>
          <TableHead className="font-bold text-gray-700">
            Cargo Class ID
          </TableHead>
          <TableHead className="font-bold text-gray-700">DOT Number</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {cargoContent.map((item, idx) => (
          <TableRow key={idx}>
            <TableCell className="text-gray-400">
              {item.cargoClassDesc || "N/A"}
            </TableCell>
            <TableCell className="text-gray-400">
              {item.id?.cargoClassId || "N/A"}
            </TableCell>
            <TableCell className="text-gray-400">
              {item.id?.dotNumber || "N/A"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CargoCarriedTable;
