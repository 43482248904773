// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";

const DocketNumbersTable = ({ docketNumbersContent = [] }) => {
  if (
    !Array.isArray(docketNumbersContent) ||
    docketNumbersContent.length === 0
  ) {
    return <p>No docket numbers available.</p>;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="font-bold text-gray-700">
            Docket Number
          </TableHead>
          <TableHead className="font-bold text-gray-700">
            Docket Number ID
          </TableHead>
          <TableHead className="font-bold text-gray-700">DOT Number</TableHead>
          <TableHead className="font-bold text-gray-700">Prefix</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {docketNumbersContent.map((item, idx) => (
          <TableRow key={idx}>
            <TableCell className="text-gray-400">
              {item.docketNumber || "N/A"}
            </TableCell>
            <TableCell className="text-gray-400">
              {item.docketNumberId || "N/A"}
            </TableCell>
            <TableCell className="text-gray-400">
              {item.dotNumber || "N/A"}
            </TableCell>
            <TableCell className="text-gray-400">
              {item.prefix || "N/A"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DocketNumbersTable;
