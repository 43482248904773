// @ts-nocheck
import React from "react";
import { Table, TableBody, TableCell, TableRow } from "./Table";

const AuthorityTable = ({ authorityContent = [] }) => {
  if (!Array.isArray(authorityContent) || authorityContent.length === 0) {
    return <p>No authority data available.</p>;
  }

  // Helper function to assign a color based on the field label and its text value.
  const getAuthorityColor = (label, value) => {
    if (!value || value === "N/A") {
      return "text-gray-400";
    }
    // Normalize the value to lowercase for comparisons.
    const normalized = value.toLowerCase();
    switch (label) {
      case "Authorized For Broker":
      case "Authorized For Household Goods":
      case "Authorized For Passenger":
      case "Authorized For Property":
        // In this example, "Yes" is considered good (green) while "No" is a red flag.
        if (normalized === "yes") return "text-green-500";
        if (normalized === "no") return "text-red-500";
        return "text-yellow-500"; // Use yellow for any other unexpected value.
      case "Broker Authority Status":
      case "Common Authority Status":
      case "Contract Authority Status":
        // Assume an "Active" status is good; anything else is considered risky.
        return normalized === "active" ? "text-green-500" : "text-red-500";
      default:
        // For fields like Applicant ID, Authority, Docket Number, DOT Number, or Prefix, use a neutral color.
        return "text-gray-400";
    }
  };

  return (
    <>
      {authorityContent.map((item, idx) => {
        const auth = item?.carrierAuthority;
        return (
          <div key={idx} className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              Authority Record {idx + 1}
            </h3>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Applicant ID
                  </TableCell>
                  <TableCell className="text-gray-400">
                    {auth?.applicantID || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Authority
                  </TableCell>
                  <TableCell className="text-gray-400">
                    {auth?.authority || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Authorized For Broker
                  </TableCell>
                  <TableCell
                    className={getAuthorityColor(
                      "Authorized For Broker",
                      auth?.authorizedForBroker
                    )}
                  >
                    {auth?.authorizedForBroker || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Authorized For Household Goods
                  </TableCell>
                  <TableCell
                    className={getAuthorityColor(
                      "Authorized For Household Goods",
                      auth?.authorizedForHouseholdGoods
                    )}
                  >
                    {auth?.authorizedForHouseholdGoods || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Authorized For Passenger
                  </TableCell>
                  <TableCell
                    className={getAuthorityColor(
                      "Authorized For Passenger",
                      auth?.authorizedForPassenger
                    )}
                  >
                    {auth?.authorizedForPassenger || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Authorized For Property
                  </TableCell>
                  <TableCell
                    className={getAuthorityColor(
                      "Authorized For Property",
                      auth?.authorizedForProperty
                    )}
                  >
                    {auth?.authorizedForProperty || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Broker Authority Status
                  </TableCell>
                  <TableCell
                    className={getAuthorityColor(
                      "Broker Authority Status",
                      auth?.brokerAuthorityStatus
                    )}
                  >
                    {auth?.brokerAuthorityStatus || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Common Authority Status
                  </TableCell>
                  <TableCell
                    className={getAuthorityColor(
                      "Common Authority Status",
                      auth?.commonAuthorityStatus
                    )}
                  >
                    {auth?.commonAuthorityStatus || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Contract Authority Status
                  </TableCell>
                  <TableCell
                    className={getAuthorityColor(
                      "Contract Authority Status",
                      auth?.contractAuthorityStatus
                    )}
                  >
                    {auth?.contractAuthorityStatus || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Docket Number
                  </TableCell>
                  <TableCell className="text-gray-400">
                    {auth?.docketNumber || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    DOT Number
                  </TableCell>
                  <TableCell className="text-gray-400">
                    {auth?.dotNumber || "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold text-gray-700">
                    Prefix
                  </TableCell>
                  <TableCell className="text-gray-400">
                    {auth?.prefix || "N/A"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
      })}
    </>
  );
};

export default AuthorityTable;
