// @ts-nocheck
import React from "react";
import CarrierBasicInfoTable from "./CarrierBasicInfoTable";
import SafetyBasicsTable from "./SafetyBasicsTable.jsx";
import CargoCarriedTable from "./CargoCarriedTable";
import OperationClassificationTable from "./OperationClassificationTable";
import DocketNumbersTable from "./DocketNumbersTable";
import AuthorityTable from "./AuthorityTable";

const DisplayCarrierData = ({ carrierData }) => {
  if (!carrierData) {
    return <p>No carrier data available.</p>;
  }

  const {
    carrierByDOT,
    carrierBasics,
    carrierCargo,
    carrierOperation,
    carrierDocketNumbers,
    carrierAuthority,
  } = carrierData;

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-gray-700">
        Carrier Basic Information
      </h2>
      {carrierByDOT?.content?.carrier ? (
        <CarrierBasicInfoTable carrier={carrierByDOT.content.carrier} />
      ) : (
        <p>No carrier basic information available.</p>
      )}

      <h2 className="text-2xl font-bold text-gray-700 mt-8">Safety Basics</h2>
      {carrierBasics?.content && carrierBasics.content.length > 0 ? (
        <SafetyBasicsTable basicsContent={carrierBasics.content} />
      ) : (
        <p>No safety basics available.</p>
      )}

      <h2 className="text-2xl font-bold text-gray-700 mt-8">Cargo Carried</h2>
      {carrierCargo?.content && carrierCargo.content.length > 0 ? (
        <CargoCarriedTable cargoContent={carrierCargo.content} />
      ) : (
        <p>No cargo carried data available.</p>
      )}

      <h2 className="text-2xl font-bold text-gray-700 mt-8">
        Operation Classification
      </h2>
      {carrierOperation?.content && carrierOperation.content.length > 0 ? (
        <OperationClassificationTable
          opClassContent={carrierOperation.content}
        />
      ) : (
        <p>No operation classification available.</p>
      )}

      <h2 className="text-2xl font-bold text-gray-700 mt-8">Docket Numbers</h2>
      {carrierDocketNumbers?.content &&
      carrierDocketNumbers.content.length > 0 ? (
        <DocketNumbersTable
          docketNumbersContent={carrierDocketNumbers.content}
        />
      ) : (
        <p>No docket numbers available.</p>
      )}

      <h2 className="text-2xl font-bold text-gray-700 mt-8">Authority</h2>
      {carrierAuthority?.content && carrierAuthority.content.length > 0 ? (
        <AuthorityTable authorityContent={carrierAuthority.content} />
      ) : (
        <p>No authority data available.</p>
      )}
    </div>
  );
};

export default DisplayCarrierData;
